import axios from "axios";
import config from "../config";
import { UrlProvider } from "./UrlManager/UrlProvider";
import Cookie from "js-cookie";

const getQueryString = (obj) =>
  Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");

export default class t {
  constructor(authToken = null) {
    this.authToken = authToken;
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  get(urlString, variables = {}, data = {}, headers = {}) {
    return this._makeRequest(urlString, variables, "get", data, headers);
  }

  post(urlString, variables, data, headers = {}) {
    return this._makeRequest(urlString, variables, "post", data, headers);
  }

  patch(urlString, variables, data, headers = {}) {
    return this._makeRequest(urlString, variables, "patch", data, headers);
  }

  put(urlString, variables, data, headers = {}) {
    return this._makeRequest(urlString, variables, "put", data, headers);
  }

  delete(urlString, variables, data, headers = {}) {
    return this._makeRequest(urlString, variables, "delete", data, headers);
  }

  _makeRequest(urlString, variables, method = "get", data = {}, headers = {}) {
    const apiUrl = config.apiUrl;

    const requestOptions = {
      url: UrlProvider.getUrl(urlString, variables, apiUrl),
      method: method,
      headers: { "Content-Type": "application/json" },
    };

    if (this.authToken) {
      requestOptions.headers["Authorization"] = `Token ${this.authToken}`;
    }

    if (["post", "put"].indexOf(method) >= 0) {
      const csrftoken = Cookie.get("csrftoken");
      if (csrftoken) {
        requestOptions.headers["X-CSRFToken"] = csrftoken;
      }
    }

    requestOptions.headers = {
      ...requestOptions.headers,
      ...headers,
    };

    if (Object.entries(data)) {
      if (method === "get") {
        requestOptions.url = `${requestOptions.url}?${getQueryString(data)}`;
      } else {
        requestOptions.data = data;
      }
    }

    return axios(requestOptions).then(
      (response) => {
        return response.data;
      },
      (error) => {
        // error.response has to be decapsulted, because we cannot throw whole error object
        // because its not serializable and fails on SSR
        const decapsulatedError = {
          data: error.response.data,
          status: error.response.status,
          statusText: error.response.statusText,
        };
        throw decapsulatedError;
      }
    );
  }
}
