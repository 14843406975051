import "./CollectedCashSummary.scss";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { PERMISSION } from "../../utils/constants";
import AuthContext from "../../contexts/AuthContext";
import config from "../../config";
import translate from "../../utils/translate";

moment.tz.setDefault(config.timeZone);

const CollectedCashSummary = ({ month, year }) => {
  const [collectedCashSummary, setCollectedCashSummary] = useState([]);
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canManageCollectedCash = hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH);

  useEffect(() => {
    apiClient
      .get("api.collectedCash.getSummary", { month, year })
      // TODO: add support for pagination
      .then((res) => setCollectedCashSummary(res.results));
  }, [apiClient, month, year]);

  return canManageCollectedCash ? (
    <div className="component-collected-cash-summary">
      <p className="margin-top">{translate("Collected cash summary")}:</p>
      <ol>
        {collectedCashSummary.map((item) => <li key={item.user.id}>
          {item.user.first_name} {item.user.last_name}
          <ul>
            <li>{translate("Collected cash amount")}: {item.collected_cash} zł</li>
            <li>{translate("Deposited cash amount")}: {item.deposited_cash} zł</li>
            {item.collected_cash - item.deposited_cash > 0 ? <li>{translate("Cash pending deposit")}: {item.collected_cash - item.deposited_cash} zł</li> : null}
          </ul>
        </li>)}
      </ol>
    </div>
  ) : null;
};

export default CollectedCashSummary;
