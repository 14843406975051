import store from "store";

export const STORE__AUTH_TOKEN = "store__auth_token";

const Store = {
  set: (key, value) => store.set(key, value),
  get: (key) => store.get(key),
};

export default Store;
