import './NotFound.scss';
import Logo from '../../components/Logo';

const NotFound = () => (
  <div className="container-not-found">
    <div>
      <Logo/>
      <h1>404!</h1>
      <div>Niestety tutaj nic nie znajdziesz... ale może pomogę na wizycie? Zapisz się <a
        href="https://calmeo.pl/umow-wizyte/">już teraz</a>!
      </div>
    </div>
  </div>
);

export default NotFound;
