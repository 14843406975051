import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import translate from "../../utils/translate";
import AuthContext from "../../contexts/AuthContext";
import { PERMISSION } from "../../utils/constants";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";

import "./TherapistSelect.scss";


const TherapistSelect = ({ setAdditionalKwargs, additionalKwargs, therapistKwargName }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canManageTransactions = hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS);

  const handleTherapistChange = (userId) => userId > 0 ? updateSearchParams(history, location, { [therapistKwargName]: userId }) : null;

  useEffect(() => {
    const selectedUserId = parseInt(getSearchParamsObject(location)[therapistKwargName]) || 0;
    setSelectedUser(selectedUserId);

    if (additionalKwargs[therapistKwargName] != selectedUserId) {
      setAdditionalKwargs({ ...additionalKwargs, [therapistKwargName]: selectedUserId > 0 ? selectedUserId : '' });
    }
  }, [location, additionalKwargs]);

  useEffect(() => {
    if (canManageTransactions) {
      apiClient
        .get("api.users.list")
        // TODO: add support for pagination
        .then((res) => res.results)
        .then((users) => setUsers(users));
    }
  }, [apiClient]);

  return users.length > 0 && canManageTransactions ? (
    <div className="component-therapist-select">
      <Form.Control
        as="select"
        onChange={(event) => handleTherapistChange(event.target.value)}
        value={selectedUser}
      >
        <option value={0}>{translate("Choose therapist")}</option>
        {users.map((user) => (
          <option
            key={user.id}
            value={user.id}
          >{`${user.last_name} ${user.first_name} (${user.employee.locations.map(location => translate(location)).join(", ")})`}</option>
        ))}
      </Form.Control>
    </div>
  ) : null;
};

export default TherapistSelect;
