import React from "react";
import ApiClient from "../utils/ApiClient.js";

export const defaultAuthContext = {
  apiClient: new ApiClient(),
  user: {},
  isLoggedIn: () => {},
  hasPermission: (permission) => {},
  updateAuthContext: () => {},
  logout: () => {},
};

const AuthContext = React.createContext(defaultAuthContext);

export default AuthContext;
