import "./Costs.scss";
import translate from "../../utils/translate";
import Transaction from "../../components/Transaction";
import DatedItemList from "../../components/DatedItemList";
import { TRANSACTION_STATUS } from "../../utils/constants";


const Costs = () => {
  return (
    <div className="container-costs">
      <DatedItemList
        apiUrlPattern="api.transactions.list"
        startDateAttribute="transaction_date__gte"
        endDateAttribute="transaction_date__lte"
        additionalKwargs={{ costs: true }}
        noItemsError={translate("No transactions in selected date.")}
        itemComponent={Transaction}
        componentParams={{ costsMode: true }}
        filteringToggleLabel={translate("Filter localized and skipped transactions")}
        filteringCallback={item => item.status !== TRANSACTION_STATUS.SKIPPED && !item.location}
      />
    </div>
  );
}

export default Costs;
