import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';

import translate from "../../utils/translate";
import { PERMISSION } from "../../utils/constants";
import AuthContext from "../../contexts/AuthContext";

import './CoordinatedEventsModal.scss';
import moment from "moment-timezone";


const CoordinatedEventsModal = ({ year, month, user_id }) => {
  const { apiClient, hasPermission } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [events, setEvents] = useState([]);
  const canDelegateEvents = hasPermission(PERMISSION.CAN_DELEGATE_EVENTS);

  useEffect(() => {
    if (showModal && canDelegateEvents) {
      apiClient
        .get("api.events.coordinatedList", { year, month }, { user_id })
        // TODO: add support for pagination
        .then((response) => setEvents(response.results));
    }
  }, [apiClient, month, year, user_id, showModal]);

  return canDelegateEvents ? (
    <span className="component-attachments-modal">
      <Button variant="primary"
              size="sm"
              onClick={() => setShowModal(true)}>
        {translate("coordinated event details")}
      </Button>

      <Modal show={showModal} centered={true} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{translate("coordinated events")}: {month}.{year}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="component-coordinated-events-modal-content">
          <ol>
            {events.map(event => <li>
              {moment(event.start_date).format("DD.MM.YYYY")},{" "}
              {moment(event.start_date).format("H:mm")} -{" "}
              {moment(event.end_date).format("H:mm")},{" "}
              <b>{event.patient.name}</b>, {event.price} zł
              {event.location ? `, ${translate(event.location)}` : null}{", "}
              {event.calendar.name}
            </li>)}
          </ol>
        </Modal.Body>
      </Modal>
    </span>
  ) : null;
};

export default CoordinatedEventsModal;
