import cx from "classnames";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import translate from "../../utils/translate";
import "./InlineSwitcher.scss";

const getButtonVariant = (selectedItem, item) =>
  selectedItem === item ? "primary" : "outline-primary";

const InlineSwitcher = ({
                          items,
                          selectedItem,
                          onItemChange,
                          disabled,
                        }) => (
  <div
    className={cx("component-inline-switcher", { disabled: disabled })}
  >
    <ButtonGroup>
      {items.map((item) => (
        <Button
          key={item}
          variant={getButtonVariant(selectedItem, item)}
          onClick={() =>
            onItemChange(
              item !== selectedItem ? item : ""
            )
          }
          disabled={disabled}
        >
          {translate(item)}
        </Button>
      ))}
    </ButtonGroup>
  </div>
);

export default InlineSwitcher;
