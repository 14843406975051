import { useState } from "react";
import Formsy from "formsy-react";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Logo from "../../components/Logo";
import AuthContext from "../../contexts/AuthContext";

import "./Login.scss";

const Login = () => {
  const [model, setModel] = useState({ user: "", password: "" });
  const [loginInProgress, setLoginInProgress] = useState(false);
  const [loginErrors, setLoginErrors] = useState([]);

  const login = (authContext) => {
    setLoginInProgress(true);
    setLoginErrors([]);

    authContext.apiClient
      .post("api.auth.login", {}, model)
      .then((user) => authContext.updateAuthContext(user))
      .catch((error) => {
        if (error.data && error.data.non_field_errors) {
          setLoginErrors(error.data.non_field_errors);
        } else {
          setLoginErrors(["Unknown login error."]);
        }
        setLoginInProgress(false);
      });
  };

  const onChange = (field, value) => {
    model[field] = value;
    setModel(model);
  };

  return (
    <AuthContext.Consumer>
      {(authContext) => (
        <div className="container-login">
          <div className="login-form-wrapper">
            <div className="logo">
              <Logo />
            </div>

            <Formsy onValidSubmit={() => login(authContext)}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="text"
                  placeholder="Login lub email..."
                  onChange={(event) => onChange("user", event.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  placeholder="Hasło..."
                  onChange={(event) => onChange("password", event.target.value)}
                  required
                />
              </Form.Group>

              {loginErrors.map((loginError, index) => (
                <Alert key={index} variant="danger">
                  {loginError}
                </Alert>
              ))}

              <Button
                variant="primary"
                type="submit"
                disabled={loginInProgress}
              >
                Zaloguj{" "}
                {loginInProgress ? (
                  <Spinner animation="grow" variant="secondary" size="sm" />
                ) : null}
              </Button>
            </Formsy>
          </div>
        </div>
      )}
    </AuthContext.Consumer>
  );
};

export default Login;
