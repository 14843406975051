import urlPatterns from "./urlPatterns";

export class UrlProvider {
  static getRawUrl(path, base = "/") {
    let url = urlPatterns;

    path.split(".").forEach((item) => {
      url = url[item];
    });

    return base + url;
  }

  static getUrl(path, args = {}, base = "/") {
    let url = this.getRawUrl(path, base);

    Object.keys(args).forEach((key) => {
      url = url.replace(":" + key, args[key]);
    });

    return url;
  }
}
