import { PAYMENT_METHOD } from "./constants";

export const isNonCashPaymentMethod = (paymentMethod) =>
  [
    PAYMENT_METHOD.STRIPE,
    PAYMENT_METHOD.TRANSFER,
    PAYMENT_METHOD.CARD,
  ].includes(paymentMethod);


export const getSearchParamsObject = (location) => Object.fromEntries(new URLSearchParams(location.search));

export const stringifySearchParamsObject = (searchParams) => Object.keys(searchParams).map(searchParam => searchParam + '=' + searchParams[searchParam]).join('&');

export const updateSearchParams = (history, location, newSearchParams) => {
  const searchParams = getSearchParamsObject(location);
  history.push({ search: stringifySearchParamsObject({ ...searchParams, ...newSearchParams }) });
};

export const roundMoney = (value) => parseInt(100 * value) / 100;

export const getAlert = (variant, message) => ({ variant, message });
export const getDangerAlert = (translate) =>
  getAlert("danger", translate("Error occured, try again"));
