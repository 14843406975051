import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";


const YearMonthSelect = ({ setAdditionalKwargs, additionalKwargs, yearKwargName = 'year', monthKwargName = 'month' }) => {
  const location = useLocation();
  const history = useHistory();

  const [dateKwargs, setDateKwargs] = useState({});
  const months = Array.apply(null, Array(12)).map((_, i) => i + 1);
  const years = Array.apply(
    null,
    Array(new Date().getFullYear() - 2021 + 1)
  ).map((_, i) => i + 2021);

  useEffect(() => {
    const year = parseInt(getSearchParamsObject(location)[yearKwargName]) || new Date().getFullYear();
    const month = parseInt(getSearchParamsObject(location)[monthKwargName]) || new Date().getMonth() + 1;
    setDateKwargs({ year, month });
    setAdditionalKwargs({ ...additionalKwargs, [yearKwargName]: year, [monthKwargName]: month });
  }, [location]);

  const onDateKwargsChange = ({ year, month }) => {
    const newSearchParams = {
      [yearKwargName]: year,
      [monthKwargName]: month,
    };

    updateSearchParams(history, location, newSearchParams);
  };

  return (
    <div className="container-year-month-select">
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Control
            as="select"
            onChange={(event) =>
              onDateKwargsChange({ ...dateKwargs, month: event.target.value })
            }
            value={dateKwargs.month}
          >
            {months.map((month) => (
              <option key={`month-${month}`} value={month}>
                {month}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Control
            as="select"
            onChange={(event) =>
              onDateKwargsChange({ ...dateKwargs, year: event.target.value })
            }
            value={dateKwargs.year}
          >
            {years.map((year) => (
              <option key={`year-${year}`} value={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form.Row>
    </div>
  );
};

export default YearMonthSelect;
