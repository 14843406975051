import { useContext, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";
import { getEventDescription } from "../../utils/eventDescriptions";
import "./EventAssigner.scss";

const EventAssigner = ({ transaction, processTransactionRequest }) => {
  const { apiClient } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [options, setOptions] = useState([]);

  const filterBy = () => true;

  const performSearch = (query) => {
    setIsLoading(true);

    apiClient
      .get("api.events.autoComplete", {}, { query })
      .then(({ results }) =>
        setOptions(
          results.map((event) => ({
            id: event.id,
            name: getEventDescription(event),
          }))
        )
      )
      .finally(() => setIsLoading(false));
  };

  const handleSearch = (query) => {
    clearTimeout(searchTimeout);
    setSearchTimeout(setTimeout(() => performSearch(query), 500));
  };

  const handleChange = (selectedEventOptions) =>
    selectedEventOptions.length > 0
      ? processTransactionRequest(
          "post",
          "api.transactions.relatedEvents",
          {
            id: transaction.id,
          },
          { event_id: selectedEventOptions[0].id }
        )
      : null;

  return (
    <div className="component-event-assigner">
      <AsyncTypeahead
        filterBy={filterBy}
        id={`event-assigner-${transaction.id}`}
        isLoading={isLoading}
        labelKey="name"
        minLength={0}
        onSearch={handleSearch}
        onChange={handleChange}
        options={options}
        placeholder={translate("Search for an event...")}
        emptyLabel={translate("No events found.")}
        promptText={translate("Type to search...")}
        useCache={false}
        clearButton={true}
      />
    </div>
  );
};

export default EventAssigner;
