const urlPatterns = {
  api: {
    auth: {
      login: "auth/login/",
      me: "auth/me/",
    },
    users: {
      list: "users/",
    },
    events: {
      list: "events/",
      listWithoutTransaction: "events-without-transaction/",
      autoComplete: "events-autocomplete/",
      edit: "events/:id",
      delegatedList: "delegated-events/",
      coordinatedList: "coordinated-events/:year/:month",
    },
    transactions: {
      edit: "transactions/:id",
      upload: "transactions/upload",
      toggleSkippedStatus: "transactions/toggle-skipped-status/:id",
      list: "transactions/",
      listNotAssigned: "not-assigned-transactions/",
      relatedEvents: "transactions/related-events/:id",
    },
    reports: {
      cashRegistry: "reports/cash-registry/:year/:month",
      eventRegistry: "reports/event-registry/:year/:month",
      timeRegistry: "reports/time-registry/:year/:month",
      receiptRegistry: "reports/receipt-registry/:year/:month",
      receiptV2Registry: "reports/receipt-v2-registry/:year/:month",
      salaryRegistry: "reports/salary-registry/:year/:month",
      incomeSummary: "reports/income-summary/:year/:month",
      locationBalanceSummary: "reports/location-balance/:year/:month",
      cashSummary: "reports/cash-summary/:year/:month",
      salaryFile: "reports/salary-file/:year/:month",
    },
    collectedCash: {
      listCreate: "collected-cash/",
      getSummary: "collected-cash-summary/:year/:month",
    },
    invoice: {
      create: "invoice/",
      email: "invoice/email",
    },
    ereceipt: {
      create: "ereceipt/",
    },
    patientAttachments: {
      create: "patient-attachments/",
      delete: "patient-attachments/:id",
    }
  },
  fe: {
    home: "/",
    delegatedEvents: "/delegated-events",
    unpaidEvents: "/unpaid-events",
    transactions: "/transactions",
    costs: "/costs",
    login: "/login",
    uploadTransactions: "/upload-transactions",
    manageCollectedCash: "/manage-collected-cash",
    reports: "/reports",
    invoiceReport: "/invoice-report",
  },
};

export default urlPatterns;
