import { useEffect, useState } from "react";
import Store, { STORE__AUTH_TOKEN } from "../../utils/Store";
import AuthContext, { defaultAuthContext } from "../../contexts/AuthContext";
import FullPageLoader from "../FullPageLoader";

const AuthProvider = ({ children }) => {
  const updateAuthContext = (user = {}) => {
    authContextState.apiClient.setAuthToken(user.token);
    Store.set(STORE__AUTH_TOKEN, user.token);
    setAuthContextState({
      ...authContextState,
      isLoggedIn: () => !!user.token,
      hasPermission: (permission) =>
        !!user.token && user.permissions.includes(permission),
      user,
    });
  };
  const logout = () => {
    updateAuthContext();
    window.location.reload();
  };

  const [authContextState, setAuthContextState] = useState({
    ...defaultAuthContext,
    isLoggedIn: () => !!(authContextState.user && authContextState.user.token),
    updateAuthContext,
    logout,
  });

  const [gettingUserDetails, setGettingUserDetails] = useState(true);

  useEffect(() => {
    const token = Store.get(STORE__AUTH_TOKEN);

    if (token) {
      authContextState.apiClient.setAuthToken(token);
      authContextState.apiClient
        .get("api.auth.me")
        .then((user) => {
          updateAuthContext(user);
        })
        .catch(() => authContextState.logout())
        .finally(() => setGettingUserDetails(false));
    } else {
      setGettingUserDetails(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={authContextState}>
      {gettingUserDetails ? <FullPageLoader /> : children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
