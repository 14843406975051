import Form from "react-bootstrap/Form";
import translate from "../../utils/translate";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";

import "./QueryInput.scss";


const QueryInput = ({ setAdditionalKwargs, additionalKwargs, queryInputKwargName = 'q' }) => {
  const [query, setQuery] = useState('');
  const [localTimeout, setLocalTimeout] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const handleQueryInputChange = (query) => {
    clearTimeout(localTimeout);
    setQuery(query);
    setLocalTimeout(setTimeout(() => updateSearchParams(history, location, { [queryInputKwargName]: query }), 500));
  };

  useEffect(() => {
    const selectedQuery = getSearchParamsObject(location)[queryInputKwargName] || '';
    setQuery(selectedQuery);
    if (additionalKwargs[queryInputKwargName] != selectedQuery) {
      setAdditionalKwargs({ ...additionalKwargs, [queryInputKwargName]: selectedQuery });
    }
  }, [location, additionalKwargs]);

  return (
    <div className="component-query-input">
      <Form.Control
        type="text"
        value={query}
        placeholder={translate("Query")}
        onChange={(event) => handleQueryInputChange(event.target.value)}
      />
    </div>
  );
};

export default QueryInput;
