import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import "react-dates/initialize";

import AuthContext from "../contexts/AuthContext";

import Navigation from "../components/Navigation";
import PrivateRoute from "../components/PrivateRoute";
import AuthProvider from "../components/AuthProvider";

import Costs from "./Costs";
import DelegatedEvents from "./DelegatedEvents";
import Events from "./Events";
import Login from "./Login";
import NotFound from "./NotFound";
import UploadTransactions from "./UploadTransactions";
import Transactions from "./Transactions";
import Reports from "./Reports";
import InvoiceReport from "./InvoiceReport";
import UnpaidEvents from "./UnpaidEvents";
import ManageCollectedCash from "./ManageCollectedCash";

import urlPatterns from "../utils/UrlManager/urlPatterns";

import "./App.scss";
import "react-dates/lib/css/_datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";



const App = () => (
  <AuthProvider>
    <Router>
      <div className="container-app">
        <Navigation/>
        <Switch>
          <PrivateRoute exact path={urlPatterns.fe.home} component={Events}/>

          {/* TODO: adjust below PrivateRoutes to allow users with proper permissions */}
          <PrivateRoute
            exact
            path={urlPatterns.fe.uploadTransactions}
            component={UploadTransactions}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.transactions}
            component={Transactions}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.costs}
            component={Costs}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.unpaidEvents}
            component={UnpaidEvents}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.reports}
            component={Reports}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.invoiceReport}
            component={InvoiceReport}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.delegatedEvents}
            component={DelegatedEvents}
          />
          <PrivateRoute
            exact
            path={urlPatterns.fe.manageCollectedCash}
            component={ManageCollectedCash}
          />

          <Route exact path={urlPatterns.fe.login}>
            <AuthContext.Consumer>
              {({ isLoggedIn }) =>
                !isLoggedIn() ? (
                  <Login/>
                ) : (
                  <Redirect to={urlPatterns.fe.home}/>
                )
              }
            </AuthContext.Consumer>
          </Route>
          <Route component={NotFound}/>
        </Switch>
      </div>
    </Router>
  </AuthProvider>
);

export default App;
