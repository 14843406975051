import Form from "react-bootstrap/Form";
import translate from "../../utils/translate";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getSearchParamsObject, updateSearchParams } from "../../utils/utils";

import "./TransactionLocationSelect.scss";
import { LOCATIONS } from "../../utils/constants";


const TRANSACTION_LOCATIONS = Object.freeze({
  ...LOCATIONS,
  WIRE_TRANSFER: "wire_transfer",
});

const TransactionLocationSelect = ({ setAdditionalKwargs, additionalKwargs, transactionLocationKwargName = 'location'}) => {
  const [transactionLocation, setTransactionLocation] = useState(TRANSACTION_LOCATIONS.ALL_LOCATIONS);
  const location = useLocation();
  const history = useHistory();

  const handleTransactionLocationChange = (transactionLocation) => transactionLocation ? updateSearchParams(history, location, { [transactionLocationKwargName]: transactionLocation }) : null;

  useEffect(() => {
    const selectedTransactionLocation = getSearchParamsObject(location)[transactionLocationKwargName] || TRANSACTION_LOCATIONS.ALL_LOCATIONS;
    setTransactionLocation(selectedTransactionLocation);
    setAdditionalKwargs({ ...additionalKwargs, [transactionLocationKwargName]: selectedTransactionLocation });
  }, [location]);

  return (
    <div className="component-transaction-location-select">
      <Form.Control
        as="select"
        onChange={(event) => handleTransactionLocationChange(event.target.value)}
        value={transactionLocation}
      >
        <option value={TRANSACTION_LOCATIONS.ALL_LOCATIONS}>{translate("Show all transactions")}</option>
        {Object.values(TRANSACTION_LOCATIONS).filter(location => location !== TRANSACTION_LOCATIONS.ALL_LOCATIONS && location !== TRANSACTION_LOCATIONS.ONLINE).map((transactionLocation) => (
          <option
            key={transactionLocation}
            value={transactionLocation}
          >{translate(transactionLocation)}</option>
        ))}
      </Form.Control>
    </div>
  );
};

export default TransactionLocationSelect;
