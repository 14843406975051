import { useCallback, useContext, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";

import { useDropzone } from "react-dropzone";

import cx from "classnames";

import translate from "../../utils/translate";
import { getDangerAlert } from "../../utils/utils";
import { PERMISSION } from "../../utils/constants";
import AuthContext from "../../contexts/AuthContext";

import './AttachmentsModal.scss';


// TODO: fetch this list from backend
const POSSIBLE_DESCRIPTIONS = [
  'Audio - kwalifikacja - 1',
  'Audio - kwalifikacja - 2',
  'Audio - kwalifikacja - 3',
  'Audio',
  'Inwentarz myśli lękowych',
  'Beck',
  'BPD check list',
  'HCL',
  'Y-BOCS',
  'Skala paniki',
  'Skala lęku uogólnionego',
  'Skala fobii społecznej',
  'Skala lęku o zdrowie',
  'Zapis objawów fizjologicznych lęku',
  'Regulamin',
  'RODO',
  'Formularz',
  'Notatki',
  'Test'
];

const AttachmentsModal = ({ event }) => {
  const { apiClient, hasPermission } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [uploadMode, setUploadMode] = useState(false);
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [alert, setAlert] = useState({});
  const canManagePatientAttachment = hasPermission(PERMISSION.CAN_MANAGE_PATIENT_ATTACHMENT);

  const getAttachmentName = attachment => attachment.description ? attachment.description : attachment.file.split("?")[0].split("/").reverse()[0];

  // TODO: move this to utils and make it generic with UploadTransaction.js (including scss)
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((acceptedFile) => {
      let formData = new FormData();
      formData.append("patient", event.patient.id)
      formData.append("description", description);
      formData.append("file", acceptedFile);

      setUploading(true);
      apiClient
        .post("api.patientAttachments.create", {}, formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((response) => setTimeout(() => window.location.reload(), 300))
        .catch((response) => setAlert(getDangerAlert(translate)))
        .finally(() => setUploading(false));
    });
  }, [description]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ["application/pdf", "image/png", "image/jpeg", "audio/mp3", "image/heic", "image/heic-sequence", "audio/mp4", "audio/x-m4a", "video/mp4"],
    onDropRejected: (fileRejections, event) => console.log(fileRejections, event),
    multiple: false,
  });

  const removeAttachment = (attachment) => {
    if (window.confirm(`${translate("Click OK to remove file")}: ${getAttachmentName(attachment)}?`)) {
      apiClient.delete("api.patientAttachments.delete", { id: attachment.id }, {})
        .then((response) => setTimeout(() => window.location.reload(), 300))
        .catch((response) => window.alert(translate("Error occured, try again")));
    }
  };

  return (
    <span className="component-attachments-modal">
      <Button variant="primary"
              size="sm"
              onClick={() => setShowModal(true)}>
        {translate("Attachments")}
      </Button>

      <Modal show={showModal} centered={true} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{event.patient.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="component-attachments-modal-content">
          <ul>
            {event.patient.attachments.map((attachment) =>
              <li key={attachment.file}>
                <a href={attachment.file} target="_blank">{getAttachmentName(attachment)}</a>
                {canManagePatientAttachment ? <span> - <a href="#" onClick={() => removeAttachment(attachment)}>usuń</a></span> : null}
              </li>
            )}
          </ul>
          {canManagePatientAttachment ?
            uploadMode ?
              uploading ? (
                <div className="uploading">
                  <Spinner animation="grow" variant="primary"/>
                </div>
              ) : (
                <>
                  {alert.message ? (
                    <Alert variant={alert.variant}>{alert.message}</Alert>
                  ) : null}
                  <Form.Control
                    as="select"
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                  >
                    <option value={0}>{translate("Choose description")}</option>
                    {POSSIBLE_DESCRIPTIONS.map((desc) => (
                      <option
                        key={desc}
                        value={desc}
                      >{desc}</option>
                    ))}
                  </Form.Control>
                  { description ? (
                    <div
                      className={cx("upload-drag-drop-area", {
                        dragging: isDragActive,
                      })}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <p>{translate("Drop the files here...")}</p>
                      ) : (
                        <p>
                          {translate(
                            "Drag 'n' drop some files here, or click to select files"
                          )}
                        </p>
                      )}
                    </div>
                  ) : null}
                </>
              )
              :
              <>
                <Button size="sm" onClick={() => setUploadMode(true)}>
                  {translate("Upload files")}
                </Button>
              </>
            : null}
        </Modal.Body>
      </Modal>
    </span>
  );
};

export default AttachmentsModal;
