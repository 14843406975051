import { useContext, useState } from "react";
import moment from "moment-timezone";
import cx from "classnames";

import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import config from "../../config";
import {
  INVOICE_REQUEST_TYPE,
  LOCATIONS,
  TRANSACTION_IMPORT_SOURCES,
  TRANSACTION_STATUS
} from "../../utils/constants";
import { getEventDescription } from "../../utils/eventDescriptions";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";

import EventAssigner from "../EventAssigner";
import DatePicker from "../DatePicker";
import InlineSwitcher from "../InlineSwitcher";
import EreceiptButton from "../EreceiptButton";

import "./Transaction.scss";


moment.tz.setDefault(config.timeZone);

const Transaction = ({ item: transaction, costsMode = false }) => {
  const { apiClient } = useContext(AuthContext);
  const [innerTransaction, setInnerTransaction] = useState(transaction);
  const [inProgress, setInProgress] = useState(false);

  const processTransactionRequest = (
    method,
    urlString,
    variables,
    data = {}
  ) => {
    setInProgress(true);
    apiClient[method](urlString, variables, data)
      .then((updatedTransaction) => setInnerTransaction(updatedTransaction))
      .finally(() => setInProgress(false));
  };

  const handleIgnore = () =>
    processTransactionRequest("post", "api.transactions.toggleSkippedStatus", {
      id: innerTransaction.id,
    });

  const removeRelatedEvent = (relatedEvent) =>
    window.confirm(
      translate("Are you sure you want to remove this event from transaction?")
    )
      ? processTransactionRequest(
          "delete",
          "api.transactions.relatedEvents",
          {
            id: innerTransaction.id,
          },
          { event_id: relatedEvent.id }
        )
      : null;

  const handleTransactionEdit = (data) =>
    processTransactionRequest(
      "patch",
      "api.transactions.edit",
      {
        id: innerTransaction.id,
      },
      data
    );

  const handleForceAssignedStatus = (forceAssignedStatus) =>
    handleTransactionEdit({
      force_assigned_status: forceAssignedStatus,
    });

  const handleAccountingDateChange = (newAccountingDate) =>
    handleTransactionEdit({
      accounting_date: newAccountingDate.format("YYYY-MM-DD"),
    });

  const handleLocationChange = (newLocation) =>
    handleTransactionEdit({
      location: newLocation || null,
    });

  return (
    <div className="component-transaction" id={`TransactionId-${innerTransaction.id}`}>
      <Card
        className={cx({
          skipped: innerTransaction.status === TRANSACTION_STATUS.SKIPPED,
          assigned: innerTransaction.status === TRANSACTION_STATUS.ASSIGNED || (costsMode && innerTransaction.location),
          "incorrectly-assigned":
            innerTransaction.status === TRANSACTION_STATUS.INCORRECTLY_ASSIGNED,
        })}
      >
        <Card.Header>
          <span>
            {moment(innerTransaction.transaction_date).format("DD.MM.YYYY")}
            {[TRANSACTION_IMPORT_SOURCES.ING_CARD, TRANSACTION_IMPORT_SOURCES.PEP_CARD].includes(innerTransaction.transaction_import_source) ? ` ${moment(innerTransaction.transaction_datetime).format("H:mm")}` : null},{" "}
            {innerTransaction.amount} zł
            {innerTransaction.accounting_date
              ? ` (${moment(innerTransaction.accounting_date).format(
                  "DD.MM.YYYY"
                )})`
              : null}
          </span>
          <small className="controls">
            {inProgress ? (
              <Spinner animation="grow" size="sm" variant="primary" />
            ) : null}
            {!innerTransaction.related_events.length &&
            [
              TRANSACTION_STATUS.NOT_ASSIGNED,
              TRANSACTION_STATUS.SKIPPED,
            ].includes(innerTransaction.status) ? (
              <span onClick={handleIgnore} className="ignore-button">
                {innerTransaction.status === TRANSACTION_STATUS.SKIPPED
                  ? translate("Unignore")
                  : translate("Ignore")}
              </span>
            ) : null}
          </small>
        </Card.Header>
        {innerTransaction.status !== TRANSACTION_STATUS.SKIPPED ? (
          <Card.Body>
            <div>
              {translate("Transfer title")}: {innerTransaction.transfer_title}
            </div>
            <div>
              {translate("Contributor")}:{" "}
              {innerTransaction.contractor_details.split(",")[0]}
            </div>
            {innerTransaction.accounting_date ? (
              <>
                <hr />
                <div className="accounting-date">
                  {translate("Accounting date")}:
                  <DatePicker
                    id={`AccountingDate-${innerTransaction.id}`}
                    date={moment(innerTransaction.accounting_date)}
                    onDateChange={handleAccountingDateChange}
                  />
                </div>
              </>
            ) : null}
            {innerTransaction.related_events.length ||
            innerTransaction.force_assigned_status ? (
              <>
                <hr />

                {innerTransaction.status !== TRANSACTION_STATUS.ASSIGNED ||
                innerTransaction.force_assigned_status ? (
                  <Form.Group
                    controlId={`ForceAssignedStatus-${innerTransaction.id}`}
                  >
                    <Form.Check
                      type="checkbox"
                      label={translate("Force transaction assigned status")}
                      checked={innerTransaction.force_assigned_status}
                      onChange={(event) =>
                        handleForceAssignedStatus(event.target.checked)
                      }
                    />
                  </Form.Group>
                ) : null}

                {innerTransaction.related_events.map((relatedEvent) => (
                  <div className="event-description" key={relatedEvent.id}>
                    {getEventDescription(relatedEvent)}
                    {relatedEvent.patient.invoice_request != INVOICE_REQUEST_TYPE.NONE ? <span className="invoice-info"> FAKTURA</span> : null}
                    {relatedEvent.status !== "cancelled" ? (
                      <>
                        {" "}
                        <EreceiptButton event={relatedEvent} size="sm" transaction={innerTransaction}></EreceiptButton>
                        {" "}
                        -{" "}
                        <span
                          className="remove-event"
                          onClick={() => removeRelatedEvent(relatedEvent)}
                        >
                          {translate("delete transaction")}
                        </span>
                      </>
                    ) : null}
                  </div>
                ))}
              </>
            ) : null}
            {innerTransaction.status !== TRANSACTION_STATUS.ASSIGNED && !costsMode ? (
              <>
                <hr />
                <EventAssigner
                  transaction={innerTransaction}
                  processTransactionRequest={processTransactionRequest}
                />
              </>
            ) : null}
            {costsMode ? (
              <>
                <InlineSwitcher
                  items={Object.values(LOCATIONS).filter(item => ![LOCATIONS.ALL_LOCATIONS, LOCATIONS.TYCHY, LOCATIONS.TYCHY_BIELSKA].includes(item) )}
                  selectedItem={innerTransaction.location}
                  onItemChange={handleLocationChange}
                  disabled={false}
                ></InlineSwitcher>
                <InlineSwitcher
                  items={Object.values(LOCATIONS).filter(item => [LOCATIONS.TYCHY, LOCATIONS.TYCHY_BIELSKA].includes(item) )}
                  selectedItem={innerTransaction.location}
                  onItemChange={handleLocationChange}
                  disabled={false}
                ></InlineSwitcher>
                <InlineSwitcher
                  items={Object.values(LOCATIONS).filter(item => [LOCATIONS.ALL_LOCATIONS].includes(item) )}
                  selectedItem={innerTransaction.location}
                  onItemChange={handleLocationChange}
                  disabled={false}
                ></InlineSwitcher>
              </>
            ) : null}
          </Card.Body>
        ) : null}
      </Card>
    </div>
  );
};

export default Transaction;
