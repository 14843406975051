import { useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";
import { roundMoney } from "../../utils/utils";
import { PERMISSION } from "../../utils/constants";


const LocationBalanceSummary = ({ month, year }) => {
  const [locationBalance, setLocationBalance] = useState([]);
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canManageTransactions = hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS);

  useEffect(() => {
    if (canManageTransactions) {
      setLocationBalance([]);
      apiClient
        .get("api.reports.locationBalanceSummary", { month, year })
        .then((res) => setLocationBalance(res));
    }
  }, [apiClient, month, year]);

  return canManageTransactions && locationBalance.length ? (
    <div>
      {locationBalance.filter(item => item.income || item.costs).map(locationBalanceItem => (<div key={locationBalanceItem.location || "no-location"}>
        {translate(locationBalanceItem.location || "No location")}:
        <ul>
          <li>{translate("Event count")}: {locationBalanceItem.events_count}</li>
          <li>{translate("Income")}: {roundMoney(locationBalanceItem.income)} zł</li>
          <li>{translate("Costs")}: {roundMoney(locationBalanceItem.costs)} zł</li>
          <li>{translate("Balance")}: <b>{roundMoney(locationBalanceItem.income - locationBalanceItem.costs)}</b> zł</li>
        </ul>
      </div>))}

      <div>
        {translate("Balance summary")}:
        <ul>
          <li>{translate("Event count")}: {locationBalance.filter(item => item.location !== 'income_prediction').reduce((sum, item) => sum + item.events_count, 0)}</li>
          <li>{translate("Income")}: {roundMoney(locationBalance.filter(item => item.location !== 'income_prediction').reduce((sum, item) => sum + item.income, 0))} zł</li>
          <li>{translate("Costs")}: {roundMoney(locationBalance.filter(item => item.location !== 'income_prediction').reduce((sum, item) => sum + item.costs, 0))} zł</li>
          <li>{translate("Balance")}: <b>{roundMoney(locationBalance.filter(item => item.location !== 'income_prediction').reduce((sum, item) => sum + item.income - item.costs, 0))}</b> zł</li>
        </ul>
      </div>
    </div>
  ) : null;
};

export default LocationBalanceSummary;
