import translate from "../../utils/translate";
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

import DatedItemList from "../../components/DatedItemList";
import Event from "../../components/Event";

import "./DelegatedEvents.scss";
import { PERMISSION } from "../../utils/constants";


const DelegatedEvents = () => {
  const { hasPermission } = useContext(AuthContext);
  const canDelegateEvents = hasPermission(PERMISSION.CAN_DELEGATE_EVENTS);

  return canDelegateEvents ? (
    <div className="container-delegated-events">
      <DatedItemList
        apiUrlPattern="api.events.delegatedList"
        startDateAttribute="start_date__gte"
        endDateAttribute="start_date__lte"
        noItemsError={translate("No events for delegation.")}
        itemComponent={Event}
        hideDateRangePicker={true}
        componentParams={{ showRecordingSharedCheckbox: true, showAttachmentsButton: true }}
        filteringToggleLabel={translate("Filter already recorded feedbacks")}
        filteringCallback={item => !item.recording_shared}
        noAutoRefresh={true}
      />
    </div>
  ) : null;
};

export default DelegatedEvents;
