import "./CollectedCash.scss";
import DatePicker from "../DatePicker";
import moment from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import translate from "../../utils/translate";
import { PERMISSION } from "../../utils/constants";
import Form from "react-bootstrap/Form";
import AuthContext from "../../contexts/AuthContext";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import config from "../../config";
import Modal from "react-bootstrap/Modal";

moment.tz.setDefault(config.timeZone);

const CollectedCash = ({ month, year, location, user }) => {
  const [showModal, setShowModal] = useState(false);
  const [collectedCash, setCollectedCash] = useState({});
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { apiClient, hasPermission, user: collector } = useContext(AuthContext);
  const canManageCollectedCash = hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH);

  useEffect(() => {
    const day = (new Date()).getDate();
    setCollectedCash({
      user: user.id,
      collector: collector.id,
      amount: 0,
      location: location,
      collected_date: `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`
    });
  }, [month, year, user, location]);

  const saveCollectedCash = () => {
    setSavingInProgress(true);
    apiClient.post("api.collectedCash.listCreate", {}, collectedCash)
      .then(() => {
        setCollectedCash({...collectedCash, amount: 0});
        setTimeout(() => window.location.reload(), 300);
      })
      .catch(() => alert(translate("Error, Collected cash not saved.")))
      .finally(() => {
        setSavingInProgress(false);
        setShowModal(false);
      });
  };

  const handleCollectedCashAmountChange = (newCollectedCashAmount) =>
    setCollectedCash({
      ...collectedCash,
      amount: newCollectedCashAmount,
    });

  return canManageCollectedCash ? (
    <span className="component-collected-cash">
      <Button variant="primary"
              size="sm"
              onClick={() => setShowModal(true)}>
        {translate("Add collected cash")}
      </Button>

      <Modal show={showModal} centered={true} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <p>{user.first_name} {user.last_name} - {translate(location)} - {month}.{year}</p>
          <Form>
            <Form.Control
              type="number"
              className="collected_cash"
              value={collectedCash.amount || ""}
              placeholder={translate("Collected cash")}
              onChange={(event) =>
                handleCollectedCashAmountChange(event.target.value)
              }
            />
            <Button
              variant="primary"
              size="sm"
              className="margin-top"
              disabled={!collectedCash.amount || savingInProgress}
              onClick={saveCollectedCash}
            >
              {translate("Save")}
              {savingInProgress ? (
                <Spinner className="spinner" animation="grow" variant="secondary" size="sm" />
              ) : null}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </span>
  ) : null;
};

export default CollectedCash;
