import { Redirect, Route } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import urlPatterns from "../../utils/UrlManager/urlPatterns";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <AuthContext.Consumer>
      {({ isLoggedIn }) => (
        <Route
          {...rest}
          render={(props) =>
            isLoggedIn() ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: urlPatterns.fe.login }} />
            )
          }
        />
      )}
    </AuthContext.Consumer>
  );
};

export default PrivateRoute;
