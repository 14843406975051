import { useContext, useState } from "react";
import cx from "classnames";
import moment from "moment-timezone";

import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import { useHistory, useLocation } from "react-router-dom";

import InlineSwitcher from "../InlineSwitcher";
import DatePicker from "../DatePicker";
import EreceiptButton from "../EreceiptButton";
import translate from "../../utils/translate";
import AuthContext from "../../contexts/AuthContext";
import { EVENT_STATUS, INVOICE_REQUEST_TYPE, PAYMENT_METHOD, PERMISSION } from "../../utils/constants";
import { getSearchParamsObject, isNonCashPaymentMethod, updateSearchParams } from "../../utils/utils";
import config from "../../config";

import "./Event.scss";
import AttachmentsModal from "../AttachmentsModal";


moment.tz.setDefault(config.timeZone);

const Event = ({ item: event, invoicesView = false, startDate = null, endDate = null, showLastInLocation = false, showRecordingSharedCheckbox = false, showTherapistName = false, showAttachmentsButton = false }) => {
  const location = useLocation();
  const history = useHistory();
  const { apiClient, user, hasPermission } = useContext(AuthContext);
  const [innerEvent, setInnerEvent] = useState(event);
  const [eventEditing, setEventEditing] = useState(false);
  const [generatingInvoice, setGeneratingInvoice] = useState(false);
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const [partlyPaidInCashTimeout, setPartlyPaidInCashTimeout] = useState(null);
  const [receiptNumberTimeout, setReceiptNumberTimeout] = useState(null);
  const canShowAttachmentsButton = showAttachmentsButton &&
    (hasPermission(PERMISSION.CAN_MANAGE_PATIENT_ATTACHMENT) || innerEvent.patient.attachments.length > 0);
  const canSkipEvents = hasPermission(PERMISSION.CAN_SKIP_EVENTS);

  const updateTimestampInSeachParams = () => {
    const searchParams = getSearchParamsObject(location);
    searchParams.timestamp = (new Date()).getTime();
    updateSearchParams(history, location, searchParams);
  };

  const handleInvoiceError = (error) => {
    if (error.data.error) {
      alert(error.data.error);
    } else {
      alert(error.data.join(', '));
    }
  };

  const generateInvoice = (innerEvent) => {
    setGeneratingInvoice(true);
    apiClient
      .post(
        "api.invoice.create",
        {},
        { eventId: innerEvent.id, startDate, endDate }
      )
      .then(() => updateTimestampInSeachParams())
      .catch((error) => {
        handleInvoiceError(error);
        setGeneratingInvoice(false);
      });
  };

  const sendInvoice = (innerEvent) => {
    setSendingInvoice(true);
    apiClient
      .post(
        "api.invoice.email",
        {},
        { eventId: innerEvent.id }
      )
      .then(() => updateTimestampInSeachParams())
      .catch((error) => handleInvoiceError(error))
      .finally(() => setSendingInvoice(false));
  };

  const editEvent = (paramsToUpdate) => {
    setEventEditing(true);
    return apiClient
      .patch(
        "api.events.edit",
        { id: innerEvent.id },
        { ...paramsToUpdate, id: innerEvent.id }
      )
      .then((event) => setInnerEvent(event))
      .finally(() => setEventEditing(false));
  };

  const onPaymentMethodChange = (paymentMethod) =>
    editEvent({ payment_method: paymentMethod })

  const onInvoiceGeneratedChange = (invoiceGenerated) =>
    editEvent({ invoice_generated: invoiceGenerated });

  const onForcedReportDateChange = (forcedReportDate) =>
    editEvent({ forced_report_date: forcedReportDate.format("YYYY-MM-DD") });

  const onRecordingSharedChange = (recordingShared) =>
    editEvent({ recording_shared: recordingShared });

  const onSkipInReportChange = (skipInReport) =>
    editEvent(
      skipInReport ?
        { status: EVENT_STATUS.SKIP_IN_REPORT, payment_method: PAYMENT_METHOD.CASH } :
        { status: EVENT_STATUS.ACTIVE, payment_method: '' }
    );

  const onMissedWithDuePaymentChange = (checked) =>
    editEvent({
      status: checked ? EVENT_STATUS.MISSED_WITH_DUE_PAYMENT : EVENT_STATUS.ACTIVE,
      payment_method: ''
    });

  const onPartlyPaidInCashChange = (element) => {
    clearTimeout(partlyPaidInCashTimeout);
    setInnerEvent({ ...innerEvent, partly_paid_in_cash: element.value });

    if (element.checkValidity()) {
      setPartlyPaidInCashTimeout(setTimeout(
        () => editEvent({ partly_paid_in_cash: element.value || 0 }), 300));
    }
  };

  const onReceiptNumberChange = (element) => {
    clearTimeout(receiptNumberTimeout);
    setInnerEvent({ ...innerEvent, receipt_number: element.value });

    if (element.checkValidity()) {
      setReceiptNumberTimeout(setTimeout(
        () => editEvent({ receipt_number: element.value || null }), 600));
    }
  };

  const toggleSkipped = () =>
    editEvent({
      status:
        innerEvent.status === EVENT_STATUS.SKIPPED
          ? EVENT_STATUS.ACTIVE
          : EVENT_STATUS.SKIPPED,
    });

  return (
    <div className="component-event" id={`EventId-${innerEvent.id}`}>
      <Card
        className={cx({
          skipped: innerEvent.status === EVENT_STATUS.SKIPPED,
          cancelled: innerEvent.status === EVENT_STATUS.CANCELLED,
          paid: innerEvent.status === EVENT_STATUS.PAID || innerEvent.status === EVENT_STATUS.SKIP_IN_REPORT,
          "missed-with-due-payment": innerEvent.status === EVENT_STATUS.MISSED_WITH_DUE_PAYMENT,
          error:
            innerEvent.status === EVENT_STATUS.PAID &&
            !innerEvent.exists_in_calendar,
          prepaid:
            innerEvent.status === EVENT_STATUS.ACTIVE &&
            isNonCashPaymentMethod(innerEvent.payment_method),
        })}
      >
        <Card.Header>
          <span>
            {moment(innerEvent.start_date).format("DD.MM.YYYY")},{" "}
            {moment(innerEvent.start_date).format("H:mm")} -{" "}
            {moment(innerEvent.end_date).format("H:mm")},{" "}
            {innerEvent.patient.name}, {innerEvent.price} zł
            {innerEvent.location ? `, ${translate(innerEvent.location)}` : null}
            {showTherapistName ? `, ${innerEvent.calendar.name}` : null}{" "}
            {canShowAttachmentsButton ? <AttachmentsModal event={innerEvent}/> : null}{" "}
            {innerEvent.patient.invoice_request != INVOICE_REQUEST_TYPE.NONE ?
              <b>({translate(innerEvent.patient.invoice_request)}, {translate(innerEvent.patient.invoicing_type)}{innerEvent.accounting_date ? `, ${translate('accounted')}: ${moment(innerEvent.accounting_date).format("DD.MM.YYYY")}` : ''})</b> : null}
          </span>
          <small className="controls">
            {eventEditing ? (
              <Spinner animation="grow" size="sm" variant="primary"/>
            ) : null}
            {canSkipEvents &&
            !innerEvent.payment_method &&
            [EVENT_STATUS.ACTIVE, EVENT_STATUS.SKIPPED].includes(
              event.status
            ) ? (
              <span onClick={toggleSkipped} className="skip-button">
                {innerEvent.status === EVENT_STATUS.SKIPPED
                  ? translate("Unignore")
                  : translate("Ignore")}
              </span>
            ) : null}
          </small>
        </Card.Header>
        {innerEvent.status !== EVENT_STATUS.SKIPPED ? (
          <Card.Body>
            {!showRecordingSharedCheckbox && !invoicesView && [EVENT_STATUS.ACTIVE, EVENT_STATUS.MISSED_WITH_DUE_PAYMENT].includes(innerEvent.status) && !innerEvent.payment_method ? (
              <Form.Group controlId={`MissedWithDuePayment-${innerEvent.id}`}>
                <Form.Check
                  type="checkbox"
                  label={translate("Patient missed the event but he is obligated to pay.")}
                  checked={innerEvent.status === EVENT_STATUS.MISSED_WITH_DUE_PAYMENT}
                  onChange={(event) =>
                    onMissedWithDuePaymentChange(event.target.checked)
                  }
                />
              </Form.Group>
            ) : null}

            {user.is_superuser && invoicesView && innerEvent.patient.invoice_request != INVOICE_REQUEST_TYPE.NONE ? (
              <Form.Group controlId={`InvoiceGenerated-${innerEvent.id}`}>
                <Form.Check
                  type="checkbox"
                  label={translate("Invoice for this event already generated.")}
                  checked={innerEvent.invoice_generated}
                  onChange={(event) =>
                    onInvoiceGeneratedChange(event.target.checked)
                  }
                />
              </Form.Group>
            ) : null}

            {user.is_superuser && invoicesView && innerEvent.patient.invoice_request === INVOICE_REQUEST_TYPE.FOR_RECEIPT ? (
              <Form>
                <Form.Control
                  type="text"
                  className="receipt-number"
                  value={innerEvent.receipt_number || ""}
                  placeholder={translate("Receipt number")}
                  disabled={innerEvent.invoice_generated || eventEditing}
                  onChange={(event) =>
                    onReceiptNumberChange(event.target)
                  }
                />
              </Form>
            ) : null}

            {user.is_superuser && invoicesView && innerEvent.invoice_id ? (
              <div className="invoice-links">
                <Button
                  href={`${config.infaktInvoiceUrl}${innerEvent.invoice_id}`}
                  target="_blank"
                >
                  {translate("See invoice")}
                </Button>
                <Button
                  onClick={() => sendInvoice(innerEvent)}
                  disabled={sendingInvoice || innerEvent.invoice_sent}
                >
                  {innerEvent.invoice_sent ? `${translate("Invoice sent")}: ${moment(innerEvent.invoice_sent).format("DD.MM.YYYY H:mm")}` : translate("Send invoice")}{" "}
                  {sendingInvoice ?
                    <Spinner className="spinner" animation="grow" variant="secondary" size="sm"/> : null}
                </Button>
              </div>
            ) : null}

            {user.is_superuser && invoicesView && !innerEvent.invoice_generated ? (
              <Button
                className="generate-invoice"
                disabled={
                  !innerEvent.patient.client_id ||
                  (innerEvent.patient.invoice_request === INVOICE_REQUEST_TYPE.FOR_RECEIPT && !innerEvent.receipt_number) ||
                  generatingInvoice
                }
                onClick={() => generateInvoice(innerEvent)}
              >
                {translate("Generate invoice")}{" "}
                {generatingInvoice ?
                  <Spinner className="spinner" animation="grow" variant="secondary" size="sm"/> : null}
              </Button>
            ) : null}

            {!showRecordingSharedCheckbox && !innerEvent.ereceipt_url && !invoicesView && user.is_superuser && (innerEvent.status === EVENT_STATUS.ACTIVE || innerEvent.status === EVENT_STATUS.SKIP_IN_REPORT) ? (
              <Form.Group controlId={`SkipInReportCheckbox-${innerEvent.id}`}>
                <Form.Check
                  type="checkbox"
                  label={translate("Skip this event in reports.")}
                  checked={innerEvent.status === EVENT_STATUS.SKIP_IN_REPORT}
                  onChange={(event) =>
                    onSkipInReportChange(event.target.checked)
                  }
                />
              </Form.Group>
            ) : null}

            {showRecordingSharedCheckbox ? (
              <Form.Group controlId={`RecordingSharedCheckbox-${innerEvent.id}`}>
                <Form.Check
                  type="checkbox"
                  label={translate("Recording shared")}
                  checked={innerEvent.recording_shared}
                  onChange={(event) =>
                    onRecordingSharedChange(event.target.checked)
                  }
                />
              </Form.Group>
            ) : null}

            {showRecordingSharedCheckbox ? (
              <div className="selected-therapist">
                {translate("Selected therapist")}: {innerEvent.calendar.name}
              </div>
            ) : null}

            {!invoicesView && user.is_superuser && (innerEvent.status === EVENT_STATUS.ACTIVE || innerEvent.status === EVENT_STATUS.PAID || innerEvent.forced_report_date) && moment(innerEvent.start_date) < moment() ? (
              <div className="forced-reporting-date">
                <span className="forced-reporting-date-label">{translate("Forced reporting date")}:</span>
                <DatePicker
                  id={`ForcedReportDateId-${innerEvent.id}`}
                  date={innerEvent.forced_report_date ? moment(innerEvent.forced_report_date) : null}
                  onDateChange={onForcedReportDateChange}
                />
              </div>
            ) : null}

            {!showRecordingSharedCheckbox && !invoicesView ? (
              <InlineSwitcher
                items={Object.values(PAYMENT_METHOD).filter(payment_method => payment_method != PAYMENT_METHOD.STRIPE)}
                selectedItem={innerEvent.payment_method}
                onItemChange={onPaymentMethodChange}
                disabled={!!innerEvent.transaction || [EVENT_STATUS.SKIP_IN_REPORT, EVENT_STATUS.MISSED_WITH_DUE_PAYMENT].includes(innerEvent.status)}
              ></InlineSwitcher>
            ) : null}

            {!invoicesView && [PAYMENT_METHOD.TRANSFER, PAYMENT_METHOD.CARD].includes(
              innerEvent.payment_method
            ) ? (
              <Form validated={true}>
                <Form.Control
                  type="number"
                  min={0}
                  max={parseInt(innerEvent.price) - 1}
                  className="partly-paid-in-cash"
                  value={innerEvent.partly_paid_in_cash || ""}
                  placeholder={translate("Partly paid in cash")}
                  onChange={(event) =>
                    onPartlyPaidInCashChange(event.target)
                  }
                />
              </Form>
            ) : null}

            {!invoicesView && innerEvent.status != EVENT_STATUS.SKIP_IN_REPORT ? (
              <EreceiptButton event={innerEvent}></EreceiptButton>
            ) : null}

            {showLastInLocation && innerEvent.is_last_in_location ? <div className="last-event-in-location">{translate("This event is last in this location.")} {translate(innerEvent.location)}</div> : null}
          </Card.Body>
        ) : null}
      </Card>
    </div>
  );
};

export default Event;
