import { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "./DatePicker.scss";

const DatePicker = (props) => {
  const [focused, setFocused] = useState(false);
  return (
    <div className="component-date-picker">
      <SingleDatePicker
        id="CalmeoDatePicker"
        displayFormat={() => "DD.MM.YYYY"}
        isOutsideRange={() => false}
        numberOfMonths={1}
        firstDayOfWeek={1}
        focused={focused}
        onFocusChange={({ focused }) => setFocused(focused)}
        withPortal
        enableOutsideDays
        {...props}
      />
    </div>
  );
};

export default DatePicker;
