import { useState } from "react";
import { DateRangePicker as SimpleDateRangePicker } from "react-dates";
import "./DateRangePicker.scss";

const DateRangePicker = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <div className="component-date-range-picker">
      <SimpleDateRangePicker
        startDateId="CalmeoDateRangePickerStartDate"
        endDateId="CalmeoDateRangePickerEndDate"
        displayFormat={() => "DD.MM.YYYY"}
        isOutsideRange={() => false}
        numberOfMonths={1}
        minimumNights={0}
        firstDayOfWeek={1}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        withPortal
        enableOutsideDays
        {...props}
      />
    </div>
  );
};

export default DateRangePicker;
