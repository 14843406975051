import translate from "../../utils/translate";
import DatedItemList from "../../components/DatedItemList";
import Event from "../../components/Event";

import "./UnpaidEvents.scss";


const UnpaidEvents = () => {
  return (
    <div className="container-unpaid-events">
      <DatedItemList
        apiUrlPattern="api.events.listWithoutTransaction"
        startDateAttribute="start_date__gte"
        endDateAttribute="start_date__lte"
        hideDateRangePicker={true}
        noItemsError={translate("No events in selected date.")}
        itemComponent={Event}
        componentParams={{showTherapistName: true}}
      />
    </div>
  );
};

export default UnpaidEvents;
