import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import translate from "../../utils/translate";
import AuthContext from "../../contexts/AuthContext";
import { INVOICE_REQUEST_TYPE, LOCATIONS, PAYMENT_METHOD } from "../../utils/constants";


const EreceiptButton = ({ event, size = null, transaction = null }) => {
  const { apiClient, user } = useContext(AuthContext);
  const [ereceiptClicked, setEreceiptClicked] = useState(false);
  const [ereceiptInProgress, setEreceiptInProgress] = useState(false);
  const [ereceiptError, setEreceiptError] = useState('');
  const [ereceiptUrl, setEreceiptUrl] = useState(event.ereceipt_url);

  const handleCreateEreceipt = () => {
    setEreceiptClicked(true);
    setEreceiptError('');

    if (event.payment_method) {
      setEreceiptInProgress(true);

      apiClient
        .post(
          "api.ereceipt.create",
          {},
          { eventId: event.id }
        )
        .then((data) => {
          setEreceiptUrl(data.ereceipt_url);
        })
        .catch((error) => {
          setEreceiptError(error.data.message || translate("Unexpected error"));
          setEreceiptInProgress(false);
        });
    }
  };

  const isEreceiptButtonEnabled = () => {
    if (event.patient.invoice_request === INVOICE_REQUEST_TYPE.FOR_TAX_NUMBER) {
      return false;
    }

    if (event.payment_method === PAYMENT_METHOD.TRANSFER && !transaction) {
      return false;
    }

    return true;
  };

  const isEreceiptButtonVisible = () => {
    if (!transaction) {
      if (event.location === LOCATIONS.TYCHY && event.start_date >= '2024-01-10') {
        return true;
      }

      if (event.location === LOCATIONS.TYCHY_BIELSKA && event.start_date >= '2024-01-10') {
        return true;
      }

      if (event.location === LOCATIONS.ONLINE && event.start_date >= '2024-01-10') {
        return true;
      }

      if (event.location === LOCATIONS.MYSLOWICE && event.start_date >= '2024-01-11') {
        return true;
      }

      if (event.location === LOCATIONS.KATOWICE && event.start_date >= '2024-01-16') {
        return true;
      }

      if (event.start_date >= '2024-01-08' && user.is_superuser) {
        return true;
      }
    } else {
      if (transaction.transaction_date >= '2024-01-07') {
        return true;
      }
    }

    return false;
  };

  return isEreceiptButtonVisible() ? (
    <span className="component-ereceipt-button">
      { ereceiptClicked && !event.payment_method ? <Alert variant="danger">{translate("Select payment method")}</Alert> : null }
      { ereceiptError ? <Alert variant="danger">{ereceiptError}</Alert> : null }
      { !ereceiptUrl ? (
        <Button
          variant="primary"
          size={size || "md"}
          onClick={() => handleCreateEreceipt()}
          disabled={ereceiptInProgress || !isEreceiptButtonEnabled()}
        >
          {translate("Create ereceipt")}{" "}
          {ereceiptInProgress ? (
            <Spinner animation="grow" variant="secondary" size="sm" />
          ) : null}
        </Button>
      ) : (
        <Button
          variant="outline-primary"
          size={size || "md"}
          href={ereceiptUrl}
          target="_blank"
        >
          {translate("See ereceipt")}
        </Button>
      ) }
    </span>
  ) : null;
};

export default EreceiptButton;
