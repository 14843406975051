import { useState } from "react";
import translate from "../../utils/translate";

import "./ShowAllEventsToggle.scss";

const ShowAllEventsToggle = ({ setAdditionalKwargs, additionalKwargs, showAllEventsKwargName }) => {
  const [showAllEvents, setShowAllEvents] = useState(additionalKwargs[showAllEventsKwargName]);

  const handleShowAllChange = () => {
    setShowAllEvents(!showAllEvents);
    setAdditionalKwargs({ ...additionalKwargs, [showAllEventsKwargName]: showAllEvents ? 0 : 1 });
  }

  return (
    <div className="component-show-all-events-toggle">
      <a href="#" className="btn btn-sm btn-secondary" onClick={handleShowAllChange}>
        {showAllEvents ? translate("Hide cancelled events") : translate("Show cancelled events")}
      </a>
    </div>
  );
};

export default ShowAllEventsToggle;
