import translate from "../../utils/translate";
import { useContext, useState } from "react";
import { PERMISSION } from "../../utils/constants";
import AuthContext from "../../contexts/AuthContext";

import YearMonthSelect from "../../components/YearMonthSelect";

import "./ManageCollectedCash.scss";
import CashSummary from "../../components/CashSummary";



const ManageCollectedCash = () => {
  const { hasPermission } = useContext(AuthContext);
  const [dateKwargs, setDateKwargs] = useState({});
  const canManageCollectedCash = hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH);

  return canManageCollectedCash ? (
    <div className="container-manage-collected-cash">
      <YearMonthSelect
        setAdditionalKwargs={setDateKwargs}
        additionalKwargs={dateKwargs}
      />

      {dateKwargs.month && dateKwargs.year ? <CashSummary month={dateKwargs.month} year={dateKwargs.year} showSkippedInReport={true} showFullReport={true}/> : null}
    </div>
  ) : null;
};

export default ManageCollectedCash;
