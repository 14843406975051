import { useContext } from "react";

import translate from "../../utils/translate";
import { PERMISSION } from "../../utils/constants";

import Event from "../../components/Event";
import DatedItemList from "../../components/DatedItemList";

import AuthContext from "../../contexts/AuthContext";

import "./InvoiceReport.scss";


const InvoiceReport = () => {
  const { hasPermission } = useContext(AuthContext);
  const canManageInvoices = hasPermission(PERMISSION.CAN_MANAGE_INVOICES);

  return canManageInvoices ? (
    <div className="container-invoice-report">
      <DatedItemList
        apiUrlPattern="api.events.list"
        startDateAttribute="start_date__gte"
        endDateAttribute="start_date__lte"
        noItemsError={translate("No events in selected date.")}
        itemComponent={Event}
        componentParams={{ invoicesView: true }}
        additionalKwargs={{ invoice_requested: 1 }}
        filteringToggleLabel={translate("Filter out sent invoices")}
        filteringCallback={item => !item.invoice_sent}
      />
    </div>
  ) : null;
};

export default InvoiceReport;
