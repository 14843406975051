import { useContext, useEffect, useState } from "react";
import { PERMISSION } from "../../utils/constants";
import AuthContext from "../../contexts/AuthContext";
import translate from "../../utils/translate";
import './CashSummary.scss';
import CollectedCash from "../CollectedCash";
import CollectedCashSummary from "../CollectedCashSummary";

const CashSummary = ({ month, year, forced_user_id: forcedUserId = '', showSkippedInReport = false, showFullReport = false }) => {
  const [cashSummary, setCashSummary] = useState([]);
  const { apiClient, hasPermission } = useContext(AuthContext);
  const canManageTransactions = hasPermission(PERMISSION.CAN_MANAGE_TRANSACTIONS);
  const canManageCollectedCash = hasPermission(PERMISSION.CAN_MANAGE_COLLECTED_CASH);

  const getSubSum = (item) => item.reduce((subsum, subitem) => subsum + subitem.amount, 0);
  const getSum = (fieldName) => cashSummary.reduce((sum, item) => sum + getSubSum(item[fieldName]), 0);

  let cashSum = getSum('cash_sum');
  let cashSumCollected = getSum('cash_sum_collected');

  useEffect(() => {
    apiClient
      .get("api.reports.cashSummary", { month, year }, { user_id: forcedUserId })
      // TODO: add support for pagination
      .then((res) => setCashSummary(res));
  }, [apiClient, month, year]);

  return cashSummary.length > 0 ? (
    <div className="component-cash-summary">
      {canManageTransactions && showSkippedInReport ? (<span>{translate("Skipped in report")}: <b>{cashSummary.reduce((sum, item) => sum + item.skipped_in_report, 0)} zł</b><br/></span>) : null}
      {translate("Cash to be collected this month")}: <b>{cashSum} zł</b>,&nbsp;
      {translate("collected so far")}: <b>{cashSumCollected} zł</b>, {translate("in envelope should be")}: <b>{cashSum - cashSumCollected} zł</b>.


      {canManageCollectedCash && showFullReport ? <>
        <p className="margin-top">{translate("Cash summary")}:</p>
        <ol>
          {cashSummary.filter(item => getSubSum(item.cash_sum) || getSubSum(item.cash_sum_collected)).map(cashSumaryItem => (
            <li key={`CashSummary-${cashSumaryItem.user.id}`} className={`cash-summary-item`}>
              {cashSumaryItem.user.last_name} {cashSumaryItem.user.first_name}:
              <ul>
                {cashSumaryItem.cash_sum.filter(cashSumItem => cashSumItem.amount).map(cashSumItem => {
                  const collectedCash = cashSumaryItem.cash_sum_collected.find(item => item.location === cashSumItem.location).amount;
                  const itemsToCommunicate = [];

                  if (collectedCash) {
                    itemsToCommunicate.push(`${translate("withdrawn")}: ${collectedCash} zł`);
                  }

                  if (cashSumItem.amount != collectedCash) {
                    itemsToCommunicate.push(`${translate("to collect")}: ${cashSumItem.amount - collectedCash} zł`);
                  }

                  return (
                    <li key={`CashSummary-${cashSumaryItem.user.id}-${cashSumItem.location}`} className={cashSumItem.amount != collectedCash ? 'not-paid' : 'paid'}>
                      {`${translate(cashSumItem.location)}: ${cashSumItem.amount} zł (${itemsToCommunicate.join(', ')})`}
                      {" "}
                      <CollectedCash location={cashSumItem.location}
                                     year={year}
                                     month={month}
                                     user={cashSumaryItem.user}>
                      </CollectedCash>
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ol>
      </>: null}
      {canManageCollectedCash && showFullReport ? <CollectedCashSummary month={month} year={year}></CollectedCashSummary> : null}
    </div>
  ) : null;
};

export default CashSummary;
