import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";
import Alert from "react-bootstrap/Alert";

import { getAlert, getDangerAlert } from "../../utils/utils";
import translate from "../../utils/translate";
import AuthContext from "../../contexts/AuthContext";
import FullPageLoader from "../../components/FullPageLoader";

import "./UploadTransactions.scss";


const UploadTransactions = () => {
  const { apiClient } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const [alert, setAlert] = useState({});
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((acceptedFile) => {
      let formData = new FormData();
      formData.append("file", acceptedFile);

      setUploading(true);
      apiClient
        .post("api.transactions.upload", {}, formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((response) =>
          setAlert(getAlert("success", translate(response.message)))
        )
        .catch((response) => setAlert(getDangerAlert(translate)))
        .finally(() => setUploading(false));
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected: () => setAlert(getDangerAlert(translate)),
    accept: ["text/csv"],
    multiple: false,
  });

  return uploading ? (
    <FullPageLoader />
  ) : (
    <div className="container-upload-transactions">
      {alert.message ? (
        <Alert variant={alert.variant}>{alert.message}</Alert>
      ) : null}
      <div
        className={cx("upload-drag-drop-area", {
          dragging: isDragActive,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{translate("Drop the files here...")}</p>
        ) : (
          <p>
            {translate(
              "Drag 'n' drop some files here, or click to select files"
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default UploadTransactions;
