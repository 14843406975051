import moment from "moment-timezone";
import translate from "./translate";
import config from "../config";
import { INVOICE_REQUEST_TYPE } from "./constants";

moment.tz.setDefault(config.timeZone);

export const getEventDescription = (event) =>
  `${
    event.status === "cancelled" ? `[${translate("CANCELLED")}] ` : ""
  }${moment(event.start_date).format("DD.MM.YYYY, HH:mm")} - ${
    event.patient.name
  }, ${event.price} zł (${event.calendar.name}) ${event.patient.invoice_request != INVOICE_REQUEST_TYPE.NONE ? `(${translate(event.patient.invoice_request)}, ${translate(event.patient.invoicing_type)})` : '' }`;

