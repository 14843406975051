import { useState } from "react";
import translate from "../../utils/translate";
import DatedItemList from "../../components/DatedItemList";
import Event from "../../components/Event";
import TherapistSelect from "../../components/TherapistSelect";
import ShowAllEventsToggle from "../../components/ShowAllEventsToggle";
import QueryInput from "../../components/QueryInput";

import "./Events.scss";


const Events = () => {
  const [additionalKwargs, setAdditionalKwargs] = useState({});

  return (
    <div className="container-events">
      <TherapistSelect
        setAdditionalKwargs={setAdditionalKwargs}
        additionalKwargs={additionalKwargs}
        therapistKwargName="calendar_owner_id"
      />

      <QueryInput additionalKwargs={additionalKwargs}
                  setAdditionalKwargs={setAdditionalKwargs}>
      </QueryInput>

      <DatedItemList
        apiUrlPattern="api.events.list"
        startDateAttribute="start_date__gte"
        endDateAttribute="start_date__lte"
        noItemsError={translate("No events in selected date.")}
        itemComponent={Event}
        componentParams={{ showLastInLocation: true, showAttachmentsButton: true }}
        additionalKwargs={additionalKwargs}
        crossContentComponent={
          <ShowAllEventsToggle
            setAdditionalKwargs={setAdditionalKwargs}
            additionalKwargs={additionalKwargs}
            showAllEventsKwargName="show_all"
          />
        }
      />
    </div>
  );
};

export default Events;
