import "./Transactions.scss";
import translate from "../../utils/translate";
import Transaction from "../../components/Transaction";
import DatedItemList from "../../components/DatedItemList";
import TransactionLocationSelect from "../../components/TransactionLocationSelect";
import QueryInput from "../../components/QueryInput";
import { TRANSACTION_STATUS } from "../../utils/constants";
import { useState } from "react";


const Transactions = () => {
  const [transactionLocationKwargs, setTransactionLocationKwargs] = useState({});

  return (
    <div className="container-transactions">
      <TransactionLocationSelect additionalKwargs={transactionLocationKwargs}
                                 setAdditionalKwargs={setTransactionLocationKwargs}>
      </TransactionLocationSelect>

      <QueryInput additionalKwargs={transactionLocationKwargs}
                  setAdditionalKwargs={setTransactionLocationKwargs}>
      </QueryInput>

      <DatedItemList
        apiUrlPattern="api.transactions.list"
        startDateAttribute="transaction_date__gte"
        endDateAttribute="transaction_date__lte"
        noItemsError={translate("No transactions in selected date.")}
        itemComponent={Transaction}
        additionalKwargs={transactionLocationKwargs}
        filteringToggleLabel={translate("Filter assigned transactions")}
        filteringCallback={item => item.status !== TRANSACTION_STATUS.ASSIGNED}
      />
    </div>
  );
}

export default Transactions;
