import Spinner from "react-bootstrap/Spinner";
import "./FullPageLoader.scss";

const FullPageLoader = () => (
  <div className="component-full-page-loader">
    <Spinner animation="grow" variant="primary" />
  </div>
);

export default FullPageLoader;
